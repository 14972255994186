@import "~antd/dist/antd.css";

.App {
  /* text-align: center; */
  background-color: black;
  height: 100;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: 'ubuntu';
  src: local('Ubuntu'), url(./assets/fonts/ubuntu/Ubuntu-B.ttf) format('ttf');
  src: local('UbuntuR'), url(./assets/fonts/ubuntu/Ubuntu-R.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.Form {
  background-color: white;
  padding: 60px;
  padding-top: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 20px;
  margin-bottom: 100px;
}

.FormEntry {
  background-color: white;
  padding: 60px;
  padding-top: 4%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 20px;
  margin-bottom: 100px;
}

.Header {
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #FF5E00;
}

.HeaderMono {
  font-family: monospace;
  color: #000;
}

.Subtext {
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 22px;
  margin-bottom: 30px;
}

.SubtextTight {
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 22px;
  margin-bottom: 10px;
}

.Description {
  font-family: UbuntuR, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
}

.SecondaryText:hover {
  color: #FF5E00;
}

.List {
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 24px;
  padding-bottom: 8px;
}

.Columns {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  vertical-align: middle;
  justify-content: center;
}

.BackButton {
  margin-top: 20px;
}

.BackButton:hover {
  color: #FF5E00;
  border-color: #FF5E00;
}

.InputContainer {
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
}

.InputField:hover {
  border-color: #FF5E00;
}

.FormButton {
  width: 100%;
  background-color: #FF5E00;
  border-width: 0px;
  font-family: Ubuntu;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 10px;
}

.FormButton:hover {
  border-color: #FF5E00
}

.FormText {
  font-size: 16px;
  font-family: Ubuntu;
}

.FormTextBold {
  color: #FF5E00;
  font-family: Ubuntu;
}

.FormTextBold:hover {
  color: #000
}

.success:focus {
  border: 2px solid red;
}

.OnboardingProgressIcon {
  max-width: 350px;
  justify-content: center;
  align-items: center
}

.Checkbox {
  display: flex;
  justify-content: center;
  border-color: #FF5E00;
  padding-top: 30px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF5E00;
  border-color: #FF5E00;
}

.ant-checkbox-inner:hover {
  border-color: #FF5E00;
}

.invalid-feedback {
  color: red;
  display: flex;
  justify-content: center;
}

.otp-input {
  width: 3em;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* antd */
.ant-btn-primary:hover {
  background-color: #000 !important;
  border-color: #000 !important;
}

.ant-input:hover {
  outline-color: #FF5E00;
}

input.success:focus {
  /*or a more specific selector*/
  border-color: red;
}

.ant-table table {
  font-size: 18px;
  font-family: Ubuntu;
}

.link {
  color: #FF5E00;
}

.link:hover {
  color: #000
}

.linkInverted {
  color: #000;
}

.linkInverted:hover {
  color: #FF5E00;
  font-weight: 500;
}

.linkGreyed {
  color: #ccc;
}

.linkGreyed:hover {
  color: #FF5E00;
}

a {
  color: #FF5E00;
  border-color: #FF5E00;
}

a:hover {
  color: #000
}

.buy-progress-bar {
  padding-bottom: 22%;
  padding-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 16px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.indexedStep.accomplished {
  background-color: #FF5E00;
  /* width: 30px;
  height: 30px; */
}

.progress-icon {
  transform: translateY(-30px);
}

.progress-icon-container {
  height: 40px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
}

.buy-progress-bar-label {
  transform: translateY(40px);
  color: #000;
  width: 80px;
  font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.progress-stage {
  justify-content: center;
  text-align: center;
}