.App {}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  border-color: #cecece;
  box-shadow: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 3px;
  border-width: 1px;
  font-weight: 300;
  color: #cecece;
}