html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

main {
    display: block
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0
}

hr {
    box-sizing: content-box;
    overflow: visible
}

pre {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

a:focus {
    outline: none !important
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

[hidden],
template {
    display: none
}

* {
    box-sizing: border-box
}

html {
    margin: 0;
    font-size: 16px;
    font-family: ubuntu, Lato, Sans-Serif;
    color: #0f0f0f;
    line-height: 1.5
}

body {
    flex-direction: column;
    min-height: 100vh
}

body,
main {
    display: flex
}

main {
    flex: 1;
    margin: 0 auto;
    width: 100%;
    flex-direction: column
}

h1 {
    letter-spacing: .5px;
    font-size: 1.5rem
}

@media (min-width:768px) {
    h1 {
        font-size: 2.625rem
    }
}

h2 {
    font-size: 1.25rem
}

h3 {
    font-size: 1rem
}

h4 {
    font-size: .875rem
}

h5,
h6 {
    font-size: .75rem
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

b,
strong {
    font-weight: 700
}

hr {
    height: 0;
    margin: 15px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #f1f1f1
}

hr:after,
hr:before {
    display: table;
    content: ""
}

hr:after {
    clear: both
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

td,
th {
    padding: 0
}

button {
    cursor: pointer;
    border-radius: 0
}

.h-mx-auto {
    margin-right: auto !important
}

.h-ml-auto,
.h-mx-auto {
    margin-left: auto !important
}

.h-mt-auto {
    margin-top: auto !important
}

.h-mb-auto {
    margin-bottom: auto !important
}

.h-m-0 {
    margin: 0 !important
}

.h-mt-0 {
    margin-top: 0 !important
}

.h-mr-0 {
    margin-right: 0 !important
}

.h-mb-0 {
    margin-bottom: 0 !important
}

.h-ml-0,
.h-mx-0 {
    margin-left: 0 !important
}

.h-mx-0 {
    margin-right: 0 !important
}

.h-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.h-m-1 {
    margin: 6px !important
}

.h-mt-1 {
    margin-top: 6px !important
}

.h-mr-1 {
    margin-right: 6px !important
}

.h-mb-1 {
    margin-bottom: 6px !important
}

.h-ml-1 {
    margin-left: 6px !important
}

.h-mt-n1 {
    margin-top: -6px !important
}

.h-mr-n1 {
    margin-right: -6px !important
}

.h-mb-n1 {
    margin-bottom: -6px !important
}

.h-ml-n1 {
    margin-left: -6px !important
}

.h-mx-1 {
    margin-right: 6px !important;
    margin-left: 6px !important
}

.h-my-1 {
    margin-top: 6px !important;
    margin-bottom: 6px !important
}

.h-m-2 {
    margin: 12px !important
}

.h-mt-2 {
    margin-top: 12px !important
}

.h-mr-2 {
    margin-right: 12px !important
}

.h-mb-2 {
    margin-bottom: 12px !important
}

.h-ml-2 {
    margin-left: 12px !important
}

.h-mt-n2 {
    margin-top: -12px !important
}

.h-mr-n2 {
    margin-right: -12px !important
}

.h-mb-n2 {
    margin-bottom: -12px !important
}

.h-ml-n2 {
    margin-left: -12px !important
}

.c-breadcrumb {
    font-size: .875rem
}

.c-breadcrumb__link {
    color: #8088a5;
    font-weight: 400
}

.c-breadcrumb__text {
    color: #0a0d27
}

.c-app-footer {
    border-top: 1px solid #ccc;
    font-size: .9rem
}

.c-app-footer,
.c-app-footer .c-app-footer__inner {
    display: flex;
    flex-direction: column;
    align-items: center
}

.c-app-footer .c-app-footer__inner {
    padding: 18px;
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    text-align: center
}

.c-app-footer .c-app-footer__inner a {
    color: #fff;
    font-weight: 500;
    margin: 12px 18px
}

.c-app-footer .c-app-footer__inner span {
    float: right;
    margin: 30px 18px 12px;
    color: #ccc
}

@media (min-width:768px) {
    .c-app-footer .c-app-footer__inner {
        flex-direction: row
    }

    .c-app-footer .c-app-footer__inner a,
    .c-app-footer .c-app-footer__inner span {
        margin: 0 18px
    }
}

.c-public-footer {
    margin-top: 80px;
    font-family: ubuntu;
    /* width: 75% */
    border-top: 1px solid #444;
    background-color: #080a15;
}

.c-public-footer__inner {
    display: grid;
    padding: 48px 30px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    grid-gap: 30px;
    color: #fff;
    white-space: normal;
    overflow: visible;
    grid-template-areas: "footer-left footer-grid-wrapper";
    grid-template-columns: 2fr 3fr
}

@media (min-width:544px) {
    .c-public-footer__inner {
        padding: 78px
    }
}

@media (max-width:1280px) {
    .c-public-footer__inner {
        grid-template-areas: "footer-left""footer-grid-wrapper";
        grid-template-columns: 1fr
    }
}

.c-public-footer__column h4 {
    margin-bottom: .5rem;
    font-size: 16px
}

.c-public-footer__column a {
    font-weight: 400;
    color: #ccc;
    line-height: 1.7;
    font-size: 14px
}

.c-public-footer__left {
    grid-area: footer-left;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

@media (max-width:1280px) {
    .c-public-footer__left {
        flex-direction: row;
        justify-content: space-between
    }
}

.c-public-footer__logo img {
    max-width: 350px;
}

.c-public-footer__copyright {
    margin-bottom: 30px
}

@media (max-width:1280px) {
    .c-public-footer__copyright {
        margin-bottom: 0
    }
}

.c-public-footer__copyright p {
    font-size: 14px;
    white-space: normal
}

@media (max-width:1280px) {
    .c-public-footer__app {
        margin-top: auto;
        margin-bottom: auto;
        display: inline-block;
    }
}

.c-public-footer__app img {
    width: 35%
}

.c-public-footer__play img {
    width: 40%;
    margin-left: -15px;
    display: inline-block;
}

.c-public-footer__app-icon:hover {
    opacity: 70%;
}

.c-public-footer__grid-wrapper {
    grid-area: footer-grid-wrapper;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start
}

.c-public-footer__grid-item {
    flex-grow: 1;
    width: 20%;
    margin: 0 40px 40px 0
}

@media (max-width:768px) {
    .c-public-footer__grid-item {
        width: 40%
    }

    .c-public-footer__grid-item:nth-child(2n) {
        margin-right: 0
    }
}

.c-public-footer__grid-item:last-child,
.c-public-footer__grid-item:nth-child(4) {
    margin-right: 0
}

.c-public-footer__grid-item-title {
    border-bottom: 1px solid #4b5066;
    white-space: normal
}

.c-public-footer__grid-item-list {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;
    white-space: normal
}

.c-public-footer__social-icon {
    height: 17px;
    display: inline-block;
    vertical-align: center;
    padding-right: 10px;
    filter: invert(100%);
}

.c-public-footer__header {
    font-family: ubuntu;
    color: #FF5E00;
    font-size: 16px;
}

.c-knowledge-base__article-top img,
.c-knowledge-base__article-widget img,
.c-knowledge-base__article-widgets-top img {
    height: 120px
}

.c-knowledge-base__hero {
    text-align: center;
    width: 100%;
    padding: 8rem 48px 78px
}

.c-link {
    user-select: none;
    color: #FF5E00;
    font-weight: 500;
    text-decoration: none;
    transition: opacity .15s linear;
    cursor: pointer
}

.c-blog__post-inner a:hover,
.c-breadcrumb__link:hover,
.c-knowledge-base__article-body a:hover,
.c-link:hover {
    color: #FF5E00
}

.c-link--with-underline {
    border-bottom: 1px solid #c5a063
}

.c-link--no-color {
    color: inherit !important
}

.c-knowledge-base__hero {
    padding-top: 48px
}

.c-knowledge-base {
    background-color: #f6f7fa;
    padding: 30px 18px;
    flex: 1
}

@media (min-width:768px) {
    .c-knowledge-base {
        padding: 48px 30px
    }
}

.c-knowledge-base__inner {
    max-width: 1012px;
    margin: 0 auto;
    color: #4b5066
}

.c-knowledge-base__inner--grid {
    display: grid;
    grid-gap: 12px
}

@media (min-width:544px) {
    .c-knowledge-base__inner--grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    }
}

.c-bitcoin-price__footer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    margin-top: 120px
}

.c-bitcoin-price__footer--left {
    margin-top: 0;
    padding: 0 30px
}

@media (min-width:544px) {
    .c-bitcoin-price__footer--left {
        margin-top: 40px
    }

    .c-bitcoin-price__footer--left p {
        margin: 0 40px
    }
}

.c-bitcoin-price__footer--icon {
    width: 100px;
    margin: 0 auto 20px
}

.c-bitcoin-price__footer--icon img {
    border: 1px solid #4b5066;
    border-radius: 16px;
    width: 100%
}

.c-bitcoin-price__footer--img {
    margin: 0 auto;
    max-width: 600px
}

.c-bitcoin-price__footer--img img {
    width: 100%;
    vertical-align: bottom
}

.c-private-client {
    height: 100%;
    color: #4b5066;
    background-color: #fff;
    font-size: 18px
}

.c-private-client a {
    color: #4b5066
}

.c-public__card-img-group img {
    padding: 0 5px
}

@media (max-width:544px) {
    .c-public__card-img-group img {
        max-height: 80%;
        max-height: 70px
    }
}

@media (min-width:768px) {
    .c-public__card-img-group-footer {
        padding-left: 50px
    }
}

@media (max-width:768px) {
    .c-public__card-img-group-footer img {
        max-height: 90px
    }

    .c-public__card-img-group-footer h1 {
        font-size: 30px
    }
}

.c-public__card-split {
    margin: 0 auto;
    max-width: 1012px;
    padding-top: 3rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8088a5
}

@media (max-width:768px) {
    .c-public__card-split {
        flex-direction: column-reverse
    }
}

@media (min-width:544px) {
    .c-public__card-split {
        padding: 3rem 0
    }
}

.c-public__card-split h3 {
    color: #4d4f66;
    font-size: 25px
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: ubuntu;
    font-display: fallback;
    src: url(../../../assets/fonts/ubuntu/Ubuntu-M.ttf) format("woff2");
    font-weight: 900;
    font-style: normal
}