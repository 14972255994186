.price-ticker {
    display: flex;
    justify-content: left;
    /* position: absolute !important; */
    /* top: 0; */
    width: 100%;
    z-index: 10 !important;
    background: color-bg(header);
    align-items: center;
    justify-content: left;
    margin-top: 20px;
    margin-left: 0;
}

.chart-actions {
    background-color: #000;
}

.spinner {
    width: 100px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
}

.currency-actions button {
    display: inline-block;
    text-align: center;
    align-self: center;
    justify-content: center;
    color: #FF5E00;
    background-color: #fff;
    border-color: #FF5E00;
    border-width: 2px;
    font-weight: 700;
    font-size: 16px;
    width: 36px;
    margin: 16px 6px;
    padding: 3px 10px;
    border: 0px solid transparent;
    border-width: 2px;
    border-color: #FF5E00;
    border-radius: 5px;
    outline: none;
}

.currency-actions button:hover {
    color: #fff;
    background-color:#FF5E00;
    border: 0px solid transparent;
    border-width: 2px;
    border-color: #FF5E00;
}

.currency-actions button:focus {
    border: 1px solid var(--white);
    border: 0px solid transparent;
    border-width: 2px;
    border-color: #FF5E00;
}

.currency-actions .active {
    background: #FF5E00;
    color: #fff;
}

/* Only display the actions when expanded */
.expanded .chart-actions {
    display: block;
}

.chart-actions button {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ccc;
    background-color: #000;
    font-weight: 700;
    font-size: 16px;
    width: 36px;
    margin: 2px 2px;
    padding: 3px 10px;
    border: 0px solid transparent;
    border-radius: 5px;
    outline: none;
}

.chart-actions button:hover {
    color: #fff
}

.chart-actions button:focus {
    border: 1px solid var(--white);
}

.chart-actions .active {
    background: #FF5E00;
    color: var(--blue);
}

.amount {
    color: #fff
}

.currency-symbol {
    color: #fff
}

.stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 18px;
    padding-right: 18px;
}

.live-price {
    display: flex;
    font-weight: 500;
    line-height: 1;
    font-size: 26px;
}

.price-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.positive-change {
    color: #00ff00;
}

.negative-change {
    color: #ff0000;
}

h3 {
    color: #FF5E00;
    margin-bottom: 6px;
    font-size: 13px;
}